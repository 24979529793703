import React from "react"
import classNames from "classnames"
import BlogPost from "./blogPost"
import { GatsbyImageData } from "../../common/types/gatsbyImageData"
import { Seo } from "../../common/types/seo"

export type BlogPostType = {
  heading: string
  summary: string
  slug?: string
  identifier?: string
  body: { html: string }
  picture: {
    url: string
    alt: string
    localFile: {
      childrenImageSharp: {
        gatsbyImageData: GatsbyImageData
      }[]
    }
  }
  blogCategories: { category: string; color: string; slug: string }[]

  seo: Seo
}

type BlogPostSectionProps = {
  posts: BlogPostType[]
}

const BlogPostSection = ({ posts }: BlogPostSectionProps) => {
  return (
    <section className="row pt-5 gy-4 px-2 px-md-0">
      {posts.map((p, i) => (
        <div
          key={i}
          data-sal="fade"
          data-sal-duration="800"
          data-sal-delay="400"
          data-sal-easing="ease"
          className={classNames("col-12", "col-md-6", "col-lg-3", {
            "col-lg-6": i === 0,
          })}
        >
          <BlogPost {...p} />
        </div>
      ))}
    </section>
  )
}

export default BlogPostSection
