import { graphql, useStaticQuery } from "gatsby"
import React, { useContext } from "react"
import AppContext from "../../common/appContext"
import { getClientTranslations } from "../layout/helperFunctions"
import BlogCategoryLabel from "./blogCategoryLabel"

export type BolgCategoryType = {
  color: string
  category: string
  slug: string
}

type BlogCategoryPickerProps = {
  category: string
}

const BlogCategoryPicker = ({ category }: BlogCategoryPickerProps) => {
  const { locale } = useContext(AppContext)

  const { allGraphCmsBlogCategory } = useStaticQuery(graphql`
    {
      allGraphCmsBlogCategory(filter: { visible: { eq: true } }) {
        nodes {
          color
          category
          slug
        }
      }
    }
  `)

  const categories: BolgCategoryType[] = allGraphCmsBlogCategory.nodes

  return (
    <div className="row py-3 py-md-5">
      <div className="col-12 d-flex flex-wrap justify-content-center align-items-center">
        <BlogCategoryLabel
          category={getClientTranslations("all", locale)}
          color={"black"}
          active={category === "all"}
          lead={category === "all"}
        />
        {categories.map((c, i) => (
          <BlogCategoryLabel
            key={i}
            category={getClientTranslations(c.category, locale)}
            color={c.color}
            slug={c.slug}
            active={category === c.category}
            lead={category === c.category}
          />
        ))}
      </div>
    </div>
  )
}

export default BlogCategoryPicker
