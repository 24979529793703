import { Link } from "gatsby"
import React from "react"
import PictureTemplate from "../common/pictureTemplate"
import BlogCategoryLabel from "./blogCategoryLabel"
import { BlogPostType } from "./blogPostSection"

const BlogPost = ({
  picture,
  blogCategories,
  heading,
  summary,
  slug = null,
}: BlogPostType) => (
  <div className="p-3 rounded move-top-on-hover neu-morphism-white">
    <div>
      {slug ? (
        <Link to={`/blog/post/${slug}`} className="u-o-1">
          <PictureTemplate className="w-100" {...picture} />
        </Link>
      ) : (
        <PictureTemplate className="w-100" {...picture} />
      )}
      <div className="d-flex pt-3">
        {blogCategories.map((bc, i) => (
          <BlogCategoryLabel
            key={i}
            category={bc.category}
            color={bc.color}
            slug={bc.slug}
          />
        ))}
      </div>
      {slug ? (
        <Link to={`/blog/post/${slug}`} className="u-o-1">
          <div className="pt-1">
            <h3>{heading}</h3>
            <p>{summary}</p>
          </div>
        </Link>
      ) : (
        <div className="pt-1">
          <h3>{heading}</h3>
          <p>{summary}</p>
        </div>
      )}
    </div>
  </div>
)

export default BlogPost
