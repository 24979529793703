import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import BlogWrapper from "../components/blog/index"
import { BlogPostType } from "../components/blog/blogPostSection"
import Wave, { WaveType } from "../components/ui/wave"
import { Locale } from "../common/appContext"

const BlogCategory = (response: any) => {
  const posts: BlogPostType[] = response.data.allGraphCmsBlogPost.nodes
  const category: string = response.data.graphCmsBlogCategory.category
  const color: string = response.data.graphCmsBlogCategory.color
  const locale: Locale =
    response.pageContext.locale === "sv" ? Locale.sv : Locale.en

  return (
    <Layout footerClassName={`u-bg-${color}`} locale={locale}>
      <SEO title={`Blog-${category}`} />
      <BlogWrapper category={category} posts={posts} />
      <Wave className={`u-c-${color}`} type={WaveType.v1} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogCategoryPageQuery($id: String!, $locale: GraphCMS_Locale!) {
    allGraphCmsBlogPost(
      sort: { order: DESC, fields: createdAt }
      filter: {
        stage: { eq: PUBLISHED }
        locale: { eq: $locale }
        blogCategories: { elemMatch: { id: { eq: $id } } }
      }
    ) {
      nodes {
        blogCategories {
          category
          color
        }
        body {
          html
        }
        heading
        summary
        slug
        picture {
          alt
          id
          url
          localFile {
            childrenImageSharp {
              gatsbyImageData(width: 596, layout: CONSTRAINED)
            }
          }
        }
      }
    }
    graphCmsBlogCategory(id: { eq: $id }) {
      category
      color
    }
  }
`

export default BlogCategory
