import React from "react"
import BlogCategoryPicker from "./blogCategoryPicker"
import BlogPostSection, { BlogPostType } from "./blogPostSection"

type BlogProps = {
  category: string
  posts: BlogPostType[]
}

const Blog = ({ category, posts }: BlogProps) => {
  return (
    <div className="container pt-5 min-vh-65">
      <section className="pt-5">
        <BlogCategoryPicker category={category} />
      </section>
      <section className="pb-5">
        <BlogPostSection posts={posts} />
      </section>
    </div>
  )
}

export default Blog
